import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { ToggleButtonGroup as MUIToggleButtonGroup } from '@mui/material';
import Button from 'components/control/button';
import { sanitizeTestid } from 'utils/stringSanitization';
import { SEARCH_OPTION_AGENTS, SEARCH_OPTION_BUY, SEARCH_OPTION_RENT, SEARCH_OPTION_SOLD } from 'themes/themeConfig';
import { trackEvent } from 'utils/google-tag-manager';
import {
  GTM_CLICK_HOMEPAGE_SEARCH_OPTION_AGENTS,
  GTM_CLICK_HOMEPAGE_SEARCH_OPTION_BUY,
  GTM_CLICK_HOMEPAGE_SEARCH_OPTION_RENT,
  GTM_CLICK_HOMEPAGE_SEARCH_OPTION_SOLD,
} from 'constants/events';

import type { SelectOptionValue, SelectOption } from '@zoocasa/node-kit/components/controls/types';

interface Props<T extends SelectOptionValue> {
  options: SelectOption<T>[];
  value: T;
  onValueChange: (option: T) => void;
  isExpTheme: boolean;
}

export default function SearchOptionButtonGroup<T extends SelectOptionValue>({ options, value, onValueChange, isExpTheme = false }: Props<T>) {
  const handleTrackEvents = (option: SelectOptionValue) => {
    if (option === SEARCH_OPTION_BUY) {
      trackEvent(GTM_CLICK_HOMEPAGE_SEARCH_OPTION_BUY);
    } else if (option === SEARCH_OPTION_RENT) {
      trackEvent(GTM_CLICK_HOMEPAGE_SEARCH_OPTION_RENT);
    } else if (option === SEARCH_OPTION_SOLD) {
      trackEvent(GTM_CLICK_HOMEPAGE_SEARCH_OPTION_SOLD);
    } else if (option === SEARCH_OPTION_AGENTS) {
      trackEvent(GTM_CLICK_HOMEPAGE_SEARCH_OPTION_AGENTS);
    }
  };

  return (
    <div className={styles.component}>
      <MUIToggleButtonGroup
        aria-label="button group"
        className={buildClassName(styles['button-group'], styles['exp-theme'])}
      >
        {options.map(({ value: option, label }, index) => (
          <Button
            key={index}
            className={buildClassName(styles['button-style'], option === value && styles['button-style-selected'])}
            onClick={() => {
              handleTrackEvents(option);
              onValueChange(option);
            }}
            type="button"
            label={label}
            testId={sanitizeTestid(label) + 'RadioButtonOption'}
          />
        ))}
      </MUIToggleButtonGroup>
    </div>
  );
}
